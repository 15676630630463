@tailwind base;
@tailwind components;
@tailwind utilities;

@import "https://js.arcgis.com/4.27/@arcgis/core/assets/esri/themes/light/main.css";
@import "react-datepicker/dist/react-datepicker.css";

/*  Roboto */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/*  Inter */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

body {
  font-family: "Inter", sans-serif;
}

.webmap {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* styles.css */

.fill-available {
  width: -webkit-fill-available;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s; /* Transition time can be adjusted or removed */
  -webkit-text-fill-color: #000 !important; /* Change text color if needed */
  box-shadow: 0 0 0px 1000px transparent inset; /* bg-blue-200 */
}

input:focus-visible {
  outline: none;
}

.esri-popup__main-container {
  width: fit-content !important;
  overflow: scroll;
}

.esri-features__container {
  background-color: #1f0318 !important ;
}

/* Global scrollbar styles */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 12px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #2e2b3f; /* Background color of the scrollbar track */
  border-radius: 10px; /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background-color: #f2c94c; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 3px solid #2e2b3f; /* Border around the thumb for spacing */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #4e47d3; /* Color of the thumb when hovered */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Makes scrollbar thinner */
  scrollbar-color: #f2c94c #2e2b3f; /* Sets the thumb and track color */
}

/* For Edge and IE */
body {
  -ms-overflow-style: none; /* Hides scrollbar in IE and Edge */
}

body::-webkit-scrollbar {
  display: none; /* Hides scrollbar in IE and Edge */
}

.esri-feature-fields__field-header,
.esri-feature-fields__field-data {
  color: white !important;
}

.esri-widget__table tr a {
  color: #f2c94c;
}

.esri-feature-content {
  margin: 0 5px 0 5px;
}

.react-datepicker__input-container {
  height: 100%;
}

.infinite-scroll-component {
  overflow: visible !important;
}
